<template>
  <ValidationObserver @submit="staffEnrollment">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-i18n="dashboard">STAFF_ENROLLMENT</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="userRole.campus_id"
            title="CAMPUS"
            label="title"
            :options="campuses"
            :search-through-api="true"
            :disabled="!!currentCampusScope"
            class="flex-1"
            reduce="id"
            rules="required"
            @search="getCampus"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span>Save</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { removeEmptyKeysFromObject } from '@src/utils/generalUtil.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    currentUser: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      userRole: {
        role_id: 'staff',
        user_id: '',
        campus_id: '',
      },
    }
  },
  computed: {
    ...mapState('layout', ['campuses', 'isLoading', 'currentCampusScope']),
  },
  created() {
    this.userRole.user_id = this.currentUser?.id
    if (this.currentCampusScope) this.userRole.campus_id = this.currentCampusScope.id
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },
    async staffEnrollment() {
      try {
        this.$store.commit('layout/IS_LOADING', true)
        removeEmptyKeysFromObject(this.userRole)
        await this.setUserRole(this.userRole)
        this.$store.commit('layout/IS_LOADING', false)
        this.$emit('toggle', 'fetch')
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    ...mapActions('staff', ['getUsers', 'setUserRole']),
    ...mapActions('layout', ['getCampusList']),
  },
}
</script>
