<template>
  <div>
    <StaffViewFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(stafflist)">
      <NoRecordFound />
    </div>

    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="
              showColumnInDataTable(filtersData, head.dataTableName, [
                'Name',
                'Phone Number',
                'Email',
              ])
            "
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
          <TH
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            Campus
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Actions')">Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(user, index) in stafflist" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            <SingleUserDisplay :user="user" label="full_name" :image="true" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Phone Number', ['Phone Number'])">
            <DisplayPhoneNumber :phone="user.phone" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Email', ['Email'])" :lowercase="true">
            {{ user.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Gender')">
            {{ user.gender || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Date Of Birth')">
            {{ (user.profile && user.profile.date_of_birth) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Join Date')">
            {{
              (user.professional_info && user.professional_info.join_date) ||
              GENERAL_CONSTANTS.NOT_APPLICABLE
            }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Blood Group')">
            {{ (user.profile && user.profile.blood_group) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Address')">
            {{ (user.profile && user.profile.address) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Religion')">
            {{ (user.profile && user.profile.religion) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Major Role')">
            {{ user.major_role || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Status')">
            <UiStatus
              :title="user.login_activated_at ? 'Login Enabled' : 'Login Disabled'"
              :success="!!user.login_activated_at"
            />
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            <BadgeDisplay
              :options="user.users_roles_entities"
              :nested-label="['campus', 'title']"
              :not-show-initials="true"
              :is-object-concated="false"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="user"
              :idx="index"
              :action-list="user.actionsList"
              @action="staffActions"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>

    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`Pagination${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(staffCounts, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />

    <StaffModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentStaff"
      @toggle="hideStaffsModal"
    />
    <!-- Activate Staff Modal -->
    <UserEnableModal @success="fetchData" />
    <UserDeactivateModal @success="fetchData" />
    <!-- Disable Modal -->
    <UserDisableModal @success="fetchData" />
    <!-- Reset Password -->
    <ResetPasswordModal @success="fetchData" />
    <UIConfirmationModal
      v-if="isSetupPassword"
      heading="User Setup Password Email"
      :message="GENERAL_CONSTANTS.emailSetupMessage"
      button-text="Sent"
      :modal="isSetupPassword"
      :name="currentStaff?.full_name"
      remaining-message=""
      @cancel="toggleUserPasswordSetupConfirmationModal"
      @confirm="sentSetupPasswordEmailHandler"
    />
    <StaffEnrollmentModal
      v-if="showEnrollmentModal"
      :modal="showEnrollmentModal"
      :current-user="currentStaff"
      @toggle="toggleStaffEnrollmentModal"
    />
  </div>
</template>

<script>
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import StaffViewFilter from './StaffViewFilter.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { isEmpty } from 'lodash'
import StaffModal from '@/src/router/views/admin/modals/StaffModal.vue'
import TableAction from '@/src/components/TableAction.vue'
import { getOptionsBasedOnUserStatus } from '@src/utils/settings/tenant-user.util.js'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import ResetPasswordModal from '@src/components/action-components/ResetPasswordModal.vue'
import StaffEnrollmentModal from '@views/staff/enrollment/StaffEnrollmentModal.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import generalMixin from '@src/mixins/general-mixins.js'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import DisplayPhoneNumber from '@src/components/UiElements/PhoneDisplay.vue'
import UserDeactivateModal from '@/src/components/action-components/DeactivateModal.vue'
import UserDisableModal from '@src/components/action-components/DisableModal.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import UserEnableModal from '@src/components/action-components/EnableLoginModal.vue'
import fileMixins from '@/src/mixins/file-mixins'
import scrollMixin from '@src/mixins/scroll-mixin'
import { staffGraphChart, rightBarStats } from '@/src/router/views/staff/staff.util.js'
import staffData from '@/src/router/views/staff/staff.json'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    SingleUserDisplay,
    StaffViewFilter,
    StaffModal,
    TableAction,
    ResetPasswordModal,
    StaffEnrollmentModal,
    Loader,
    BadgeDisplay,
    Pagination,
    UserDeactivateModal,
    UserDisableModal,
    DisplayPhoneNumber,
    UserEnableModal,
    NoRecordFound,
    UIConfirmationModal,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixin, fileMixins, scrollMixin],
  data() {
    return {
      forceRender: 0,
      filtersData: {},
      isSetupPassword: false,
      isEmpty,
      GENERAL_CONSTANTS,
      dashboard: 'dashboard',
      options: ['Select'],
      alwaysDisplayColumnsForCSV: staffData.alwaysDisplayColumnsForCSV,
      tableHeaders: staffData.tableHeaders,
      defaultSelectedColumns: staffData.defaultSelectedColumns,
      stafflist: [],
      currentStaff: null,
      actionList: null,
      showEnrollmentModal: false,
      showPagination: false,
      filteredRecordLimit: 10,
      isLoading: false,
      staffCounts: null,
    }
  },
  computed: {
    ...mapState({
      enableLoginModal: (state) => state.layout.enableLoginModal,
      showDeactivateModal: (state) => state.layout.showDeactivateModal,
      showDisableModal: (state) => state.layout.showDisableModal,
      resetPassword: (state) => state.layout.resetPassword,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      tabRightBar: (state) => state.layout.tabRightNav,
      showModal: (state) => state.layout.showModal,
      classId: (state) => state.layout.currentSectionScope,
      campusId: (state) => state.layout.currentCampusScope.id,
      campus: (state) => state.layout.currentCampusScope,
    }),
    modalValueChanges() {
      return (
        this.enableLoginModal ||
        this.showDeactivateModal ||
        this.showDisableModal ||
        this.resetPassword
      )
    },
    ...mapGetters('layout', ['isManualPasswordResetAllowed']),
  },
  watch: {
    modalValueChanges: {
      handler(value) {
        if (!value) this.currentStaff = null
      },
    },
    campusId: {
      deep: true,
      handler() {
        this.filterRecord()
      },
    },
    classId: {
      deep: true,
      handler() {
        this.filterRecord()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchStaffStats()
      },
    },
  },
  page: staffData.page,
  mounted() {
    this.setRightbarData()
    this.filterRecord()
  },
  methods: {
    showColumnInDataTable,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
      this.forceRender++
    },
    fetchData() {
      this.forceRender++
      this.filterRecord()
    },
    filterRecord(range) {
      this.isLoading = true

      let payload = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload

      this.getStaffWithFilters(payload)
        .then((res) => {
          this.stafflist = res?.data?.records
          this.stafflist?.forEach((staff) => {
            staff.actionsList = this.tableActionList(staff.login_activated_at)
          })
          this.staffCounts = res?.data?.meta?.total_records
          this.fetchStaffStats({ $where: this.filtersData?.$where })
          this.showPagination = this.staffCounts > GENERAL_CONSTANTS.RECORD_LIMIT
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * Create table action list dynamicaaly
     * @param {String} userStaus
     * @return {Array} actionsArray
     */

    tableActionList(userStatus) {
      this.actionList = [{ name: 'Edit' }, { name: 'TV_DEACTIVATE_USER' }]
      if (!this.currentCampusScope) {
        this.actionList.push({ name: 'TV_A' })
      }
      let options = getOptionsBasedOnUserStatus(userStatus, this.isManualPasswordResetAllowed)
      this.actionList = [...this.actionList, ...options]

      return this.actionList
    },
    /**
     * Perform action on staff
     * @param {String} action - WHich action to perform
     * @param {Object} current - Current selected object
     */
    staffActions(action, current) {
      this.currentStaff = current
      this.$store.commit('layout/SET_CURRENT_SELECTED_USER', this.currentStaff)
      switch (action) {
        case 'Edit':
          this.showStaffEditModal()
          break
        case 'TV_ACTIVATE_ACCOUNT':
          this.$store.commit('layout/ENABLE_LOGIN_MODAL', true)
          this.currentStaff = null
          break
        case 'TV_RE':
          this.toggleUserPasswordSetupConfirmationModal()
          break

        case 'TV_DEACTIVATE_USER':
          this.$store.commit('layout/SET_SHOW_DEACTIVATE_MODAL', true)
          this.currentStaff = null
          break
        case 'TV_SUSPEND_ACCOUNT':
          this.$store.commit('layout/SET_SHOW_DISABLE_MODAL', true)
          this.currentStaff = null
          break
        case 'TV_RP':
          this.$store.commit('layout/RESET_PASSWORD_MODAL', true)
          this.currentStaff = null
          break
        case 'TV_A':
          this.toggleStaffEnrollmentModal()
          break
      }
    },
    /**
     * Toggle staff enrollment modal
     */
    toggleStaffEnrollmentModal(payload) {
      this.showEnrollmentModal = !this.showEnrollmentModal
      if (!this.showEnrollmentModal) this.currentStaff = null
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
    },
    toggleUserPasswordSetupConfirmationModal() {
      this.isSetupPassword = !this.isSetupPassword
      if (!this.isSetupPassword) this.currentStaff = null
    },

    async sentSetupPasswordEmailHandler() {
      let payload = { user_id: this.currentStaff?.id }
      await this.resendUserSetupPassword(payload)
      this.toggleUserPasswordSetupConfirmationModal()
    },
    /**
     * Toggle edit staff modal
     */
    showStaffEditModal() {
      this.$store.dispatch('layout/setShowModal', true)
    },
    /**
     * Disable Add new Staff or edit existing staff modal
     * @param {Boolean} payload - Checks its complete its job successfully
     */

    hideStaffsModal(payload) {
      this.$store.dispatch('layout/setShowModal', false)
      this.currentStaff = null
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
    },
    fetchStaffStats(payload = { $where: {} }) {
      this.getStaffStats(payload).then((res) => {
        this.setRightbarData(res)
      })
    },
    /**
     * Deactivate Staff
     * @param {Strinf} id- Staff id
     */
    setRightbarData(stats) {
      let BAR_GRAPH_VALUE = [
        stats?.total_staff,
        stats?.management_staff_count,
        stats?.teaching_staff_count,
        stats?.support_team_staff_count,
        stats?.human_resource_staff_count,
        stats?.finance_staff_count,
        stats?.other_staff_count,
      ]
      let PIE_GRAPH_VALUE = [
        stats?.total_staff - stats?.login_enabled_count,
        stats?.login_enabled_count,
      ]
      let rightBarContent = {
        header: {
          title: 'SV_RB_HT',
          buttons: [
            {
              title: 'SV_RB_BT',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: rightBarStats(),
        chartData: staffGraphChart(BAR_GRAPH_VALUE, PIE_GRAPH_VALUE),
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('staff', ['getStaffStats', 'getStaffWithFilters']),
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('auth', ['resendUserSetupPassword']),
  },
}
</script>
